import {React, useContext, useEffect} from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import LuggageIcon from '@mui/icons-material/Luggage';
import GroupIcon from '@mui/icons-material/Group';
import { TransportArtContext } from '../../context/TransportArt';
import { DurationContext } from '../../context/DurationContext';
import { RabattcodeContext } from '../../context/RabattcodeContext';
import { LanguageContext } from "../../context/LanguageContext";
import { PriceContext } from '../../context/PriceContext';
import { TextData } from '../../source/textData';

import { DiscountData } from '../../source/discountData';


function CarListItem({car,distance,selectedCar,handleChange}) {

const { language } = useContext(LanguageContext);
const {transportArt, setTransportArt} = useContext(TransportArtContext);
const {duration, setDuration} = useContext(DurationContext);
const {rabattcode, setRabattcode} = useContext(RabattcodeContext);
const {priceContext, setPriceContext} = useContext(PriceContext);


let rabattCodeStatus = false;

let result1;
let roundedResult1;
let formattedResult1;
let roundedDistance = Math.round(distance * 100) / 100;
//console.log(roundedDistance);
let result2 = car.amount20km * (duration / 20);

if (roundedDistance < 60) {
    result1 = car.amount_under_60km * roundedDistance;
} else if (roundedDistance > 60 && distance <= 200) {
    result1 = car.amount_above_60km * roundedDistance;
} else {
    result1 = car.amount_above_200km * roundedDistance;
}

let beforeRabattcodePrice1 = result1;
let beforeRabattcodePrice2 = result2;

if(rabattcode === DiscountData.discount[language].discountcode1_1 || rabattcode === DiscountData.discount[language].discountcode1_2){
    result1 = result1 * 0.9;
    result2 = result2 * 0.9;
    rabattCodeStatus = true;
}else if(rabattcode === DiscountData.discount[language].discountcode2_1 || rabattcode === DiscountData.discount[language].discountcode2_2){
    result1 = result1 * 0.85;
    result2 = result2 * 0.85;
    rabattCodeStatus = true;
}else if(rabattcode === DiscountData.discount[language].discountcode3_1 || rabattcode === DiscountData.discount[language].discountcode3_2){
    result1 = result1 * 0.8;
    result2 = result2 * 0.8;
    rabattCodeStatus = true;
}else{
    rabattCodeStatus = false;
}

let RoundedbeforeRabattcodePrice1 = Math.ceil(beforeRabattcodePrice1 / 5) * 5;
let RoundedbeforeRabattcodePrice2 = Math.ceil(beforeRabattcodePrice2 / 5) * 5;
let RoundedformattedResult1 = RoundedbeforeRabattcodePrice1.toFixed(0);
let RoundedformattedResult2 = RoundedbeforeRabattcodePrice2.toFixed(0);

if(RoundedformattedResult1 < 60){
    RoundedformattedResult1 = 60;
}

if(RoundedformattedResult2 < 60){
    RoundedformattedResult2 = 60;
}

roundedResult1 = Math.ceil(result1 / 5) * 5;
formattedResult1 = roundedResult1.toFixed(0);

const roundedResult2 = Math.ceil(result2 / 5) * 5;
const formattedResult2 = roundedResult2.toFixed(0);

if (formattedResult1 < 60) {
    formattedResult1 = 60;
}

if (formattedResult2 < 60) {
    formattedResult2 = 60;
}

useEffect(() => {
    if(selectedCar === car.name){
        const updatedPriceContext = {
            price1: formattedResult1,
            price2: formattedResult2
        };
        setPriceContext(updatedPriceContext);
    }
}, [selectedCar, formattedResult1, formattedResult2]);


  return (
    <div className="carlistitem-wrapper">
        <div className="carlistitem">
            <Radio
              checked={selectedCar === car.name}
              onChange={handleChange}
              value={car.name}
              name="radio-buttons"
              inputProps={{ 'aria-label': 'A' }}
            />
            <div className="carlistitem-inner">
            <img alt={car.name} src={car.image}/>
            </div>
            <div className="carlistitem-texts">
                  
                <h2>{car[language].name}</h2>                    
                <p className="car-description">{car[language].desc}</p>
                <div className="carlistitem-icon-wrapper">
                  <div>
                      <p className="carlistitem-icon carlistitem-icon1"><GroupIcon/> {car.seat}</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon2"><LuggageIcon/> {car.luggage}</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon3"><LuggageIcon/> {car.luggagesmall}</p>
                  </div>
                </div>
                {transportArt === 0 ? (
                      distance ? (
                        rabattCodeStatus ? (
                            <div className="rabatt-wrapper">
                                <h2 className="carlistitem-price rabatt-old">{RoundedformattedResult1} €</h2>
                                <h2 className="carlistitem-price rabatt-new">{formattedResult1} €</h2>
                            </div>
                        ) : (
                            <h2 className="carlistitem-price">{formattedResult1} €</h2>
                        )
                          
                      ) : (
                          <h2 className="carlistitem-price">0.00 €</h2>
                      )
                  ) : (
                      duration ? (
                        rabattCodeStatus ? (
                            <div className="rabatt-wrapper">
                                <h2 className="carlistitem-price rabatt-old" style={{textDecoration: 'line-through'}}>{RoundedformattedResult2} €</h2>
                                <h2 className="carlistitem-price rabatt-new">{formattedResult2} €</h2>
                            </div>
                        ) : (
                            <h2 className="carlistitem-price">{formattedResult2} €</h2>
                        )
                      ) : (
                          <h2 className="carlistitem-price">0.00 €</h2>
                      )
                  )}
            </div>
        </div>
    </div>
  )
}

export default CarListItem