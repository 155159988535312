import React, {useContext} from 'react';
import './impressum.scss';
import Footer from '../../components/footer/Footer';
import NavbarWeb from "../../components/navbarWeb/NavbarWeb";
import Container from '@mui/material/Container';
import { LegalData } from '../../source/legalData';
import { LanguageContext } from "../../context/LanguageContext";


function Impressum() {

  const { language } = useContext(LanguageContext);


  return (
    <div className="datenschutz">
        <NavbarWeb scrolled={true}/>
        <div className="datenschutzContainer">
        <Container maxWidth="lg" >
          <h1>{LegalData.imprint[language].title}</h1>
          <h2>{LegalData.imprint[language].subtitle1}</h2>
          <p>{LegalData.imprint[language].subtitle1_desc1}</p>
          <p>{LegalData.imprint[language].subtitle1_desc2}</p>
          <p className="space-bottom">{LegalData.imprint[language].subtitle1_desc3}</p>

          <p>{LegalData.imprint[language].subtitle1_desc4}</p>
          <p >{LegalData.imprint[language].subtitle1_desc5}</p>
          <p className="space-bottom">{LegalData.imprint[language].subtitle1_desc6}</p>
          <p className="space-bottom">{LegalData.imprint[language].subtitle1_desc7}</p>
          <p>{LegalData.imprint[language].subtitle1_desc8}</p>
          <p className="space-bottom">{LegalData.imprint[language].subtitle1_desc9}</p>
          <p>{LegalData.imprint[language].subtitle1_desc10}</p>
          <p>{LegalData.imprint[language].subtitle1_desc11}</p>

          <h2>{LegalData.imprint[language].subtitle2}</h2>
          <p>{LegalData.imprint[language].subtitle2_desc1}</p>
          <p>{LegalData.imprint[language].subtitle2_desc2}</p>

          <h2>{LegalData.imprint[language].subtitle3}</h2>
          <p>{LegalData.imprint[language].subtitle3_desc1}</p>

          <h2>{LegalData.imprint[language].subtitle4}</h2>
          <p>{LegalData.imprint[language].subtitle4_desc1}</p>

          <h3>{LegalData.imprint[language].subtitle5}</h3>
          <p>{LegalData.imprint[language].subtitle5_desc1}</p>

          <h3>{LegalData.imprint[language].subtitle6}</h3>
          <p>{LegalData.imprint[language].subtitle6_desc1}</p>

          <h3>{LegalData.imprint[language].subtitle7}</h3>
          <p>{LegalData.imprint[language].subtitle7_desc1}</p>

        </Container>
        </div>
        <Footer/>
    </div>    
  )
}

export default Impressum