export const TextData = {

    menu: {
        DE: {
            item1: "Home",
            item2: "Leistungen",
            item3: "Karriere",
            item4: "Buchung",
            item5: "Kontakt",
        },
        EN: {
            item1: "Home",
            item2: "Services",
            item3: "Careers",
            item4: "Booking",
            item5: "Contact",
        }
    },


    header: {
        DE: {
            subtitle: "VIP Driver's GmbH",
            title: "IHR CHAUFFEUR- & LIMOUSINENSERVICE IN MÜNCHEN UND UMGEBUNG",
            description: "VIP Driver's - Ihr verlässlicher Partner für professionelle Fahrdienste. Buchen Sie noch heute und erleben Sie eine entspannte und erfrischende Reise mit uns! Entdecken Sie unseren erstklassigen Service für private und geschäftliche Fahrten.",
        },
        EN: {
            subtitle: "VIP Driver's GmbH",
            title: "YOUR CHAUFFEUR & LIMOUSINE SERVICE IN MUNICH AND SURROUNDINGS",
            description: "VIP Driver's - Your reliable partner for professional chauffeur services. Book today and enjoy a relaxed and refreshing journey with us! Explore our first-class services for private and business trips, tailored to your individual needs."
        }
    },

    searchSection: {
        DE: {
            tab1: "TRANSFER",
            tab2: "STÜNDLICH",

            input1: "Abholort",
            input2: "Zielort",
            input3: "Dauer",
            input4: "Datum",
            input5: "Zeit",
            
            dauer1: "2 Stunden (inkl. 40 km)",
            dauer2: "3 Stunden (inkl. 60 km)",
            dauer3: "4 Stunden (inkl. 80 km)",
            dauer4: "5 Stunden (inkl. 100 km)",
            dauer5: "6 Stunden (inkl. 120 km)",
            dauer6: "7 Stunden (inkl. 140 km)",
            dauer7: "8 Stunden (inkl. 160 km)",
            dauer8: "9 Stunden (inkl. 180 km)",
            dauer9: "10 Stunden (inkl. 200 km)",
            dauer10: "11 Stunden (inkl. 220 km)",
            dauer11: "12 Stunden (inkl. 240 km)",
            dauer12: "13 Stunden (inkl. 260 km)",
            dauer13: "14 Stunden (inkl. 280 km)",
            dauer14: "15 Stunden (inkl. 300 km)",
            dauer15: "16 Stunden (inkl. 320 km)",
            dauer16: "17 Stunden (inkl. 340 km)",
            dauer17: "18 Stunden (inkl. 360 km)",
            dauer18: "19 Stunden (inkl. 380 km)",
            dauer19: "20 Stunden (inkl. 400 km)",
            dauer20: "21 Stunden (inkl. 420 km)",
            dauer21: "22 Stunden (inkl. 440 km)",
            dauer22: "23 Stunden (inkl. 460 km)",
            dauer23: "24 Stunden (inkl. 480 km)",
            dauer24: "2 Tage (inkl. 960 km)",
            dauer25: "3 Tage (inkl. 1440 km)",
            dauer26: "4 Tage (inkl. 1920 km)",
            dauer27: "5 Tage (inkl. 2400 km)",

            button: "Jetzt Buchen!"
        },
        EN: {
            tab1: "TRANSFER",
            tab2: "HOURLY",
            
            input1: "Pick-up Location",
            input2: "Destination",
            input3: "Duration",
            input4: "Date",
            input5: "Time",

            dauer1: "2 hours (incl. 40 km)",
            dauer2: "3 hours (incl. 60 km)",
            dauer3: "4 hours (incl. 80 km)",
            dauer4: "5 hours (incl. 100 km)",
            dauer5: "6 hours (incl. 120 km)",
            dauer6: "7 hours (incl. 140 km)",
            dauer7: "8 hours (incl. 160 km)",
            dauer8: "9 hours (incl. 180 km)",
            dauer9: "10 hours (incl. 200 km)",
            dauer10: "11 hours (incl. 220 km)",
            dauer11: "12 hours (incl. 240 km)",
            dauer12: "13 hours (incl. 260 km)",
            dauer13: "14 hours (incl. 280 km)",
            dauer14: "15 hours (incl. 300 km)",
            dauer15: "16 hours (incl. 320 km)",
            dauer16: "17 hours (incl. 340 km)",
            dauer17: "18 hours (incl. 360 km)",
            dauer18: "19 hours (incl. 380 km)",
            dauer19: "20 hours (incl. 400 km)",
            dauer20: "21 hours (incl. 420 km)",
            dauer21: "22 hours (incl. 440 km)",
            dauer22: "23 hours (incl. 460 km)",
            dauer23: "24 hours (incl. 480 km)",
            dauer24: "2 days (incl. 960 km)",
            dauer25: "3 days (incl. 1440 km)",
            dauer26: "4 days (incl. 1920 km)",
            dauer27: "5 days (incl. 2400 km)",
            button: "Book Now!"
        }
    },

    homeContent: {
        DE: {
            section1: {
                title: "Willkommen bei VIP Driver's GmbH",
                description: "Wir bieten einen führenden Chauffeurservice für luxuriöse Fahrdienstleistungen, der ein unvergleichliches Erlebnis auf höchstem Niveau garantiert. Bei VIP Driver’s steht Komfort, Sicherheit und Stil im Mittelpunkt, um unseren anspruchsvollen Kunden die bestmögliche Erfahrung zu bieten. Unser Team erfahrener und engagierter professioneller Chauffeure strebt stets nach höchster Servicequalität und Kundenzufriedenheit. Unsere Leidenschaft für außergewöhnlichen Kundenservice treibt uns an, Ihre Erwartungen zu übertreffen.",
                service1_1: "Chauffeurservice",
                service1_2: " für komfortable und stilvolle Fahrten",

                service2_1: "Flughafentransfers",
                service2_2: " mit erstklassigem Komfort und pünktlicher Abholung",

                service3_1: "Langstreckentransfers",
                service3_2: " für entspannte und sichere Reisen",

                service4_1: "Besorgungs- und Kurierfahrten",
                service4_2: " mit zuverlässigem und schnellem Service"

            }, 
            section2: {
                title: "Unsere maßgeschneiderten Lösungen für Ihre Bedürfnisse",
                description: "Entdecken Sie unsere maßgeschneiderten Lösungen für Ihre Bedürfnisse! Unsere Dienstleistungen umfassen Flughafentransfers, Stadtfahrten, Geschäftsreisen, Besorgungsfahrten und individuelle Touren. Wir bieten auch maßgeschneiderte Optionen, um sicherzustellen, dass Ihre spezifischen Anforderungen erfüllt werden. Profitieren Sie von unserer Professionalität und Diskretion. Unsere transparenten Festpreise garantieren, dass Sie keine versteckten Kosten haben und immer genau wissen, was Sie bezahlen.",
                service1_1: "Exklusiver Limousinenservice",
                service1_2: " für anspruchsvolle Kunden",

                service2_1: "Flexible und individuelle Touren",
                service2_2: ", angepasst an Ihre Bedürfnisse und Interessen",

                service3_1: "Stündliche Buchungen",
                service3_2: " für maximale Flexibilität und Bequemlichkeit",

                service4_1: "Veranstaltungsfahrten",
                service4_2: " für reibungslose Abläufe bei Events"

            },
            section3: {
                title: "Erstklassige Fahrzeuge für Ihren Komfort",
                description: "Entdecken Sie mit VIP Driver’s GmbH den ultimativen Chauffeurservice. Unser breiter Fuhrpark bietet luxuriöse Limousinen, komfortable SUVs und elegante Vans. Alle Fahrzeuge werden sorgfältig gewartet und gereinigt, um Ihre Fahrt zu einem unvergesslichen Erlebnis zu machen. Lassen Sie uns Ihre Reisepläne in die Hand nehmen und Ihnen ein unvergleichliches Fahrerlebnis bieten. Wir freuen uns darauf, Sie zu befördern!",
                service1_1: "Shuttle",
                service1_2: " für bequeme und zuverlässige Transporte zu Veranstaltungen, Hotels oder anderen Zielen",

                service2_1: "Besichtigungstouren",
                service2_2: " für kulturelle Entdeckungen und historische Highlights",

                service3_1: "Ausflugsfahrten",
                service3_2: " zu den schönsten und interessantesten Orten",

                service4_1: "Romantische Hochzeiten",
                service4_2: " mit eleganten Fahrzeugen und professionellem Service"

            }
        },
        EN: {
            section1: {
                title: "Welcome to VIP Driver's GmbH",
                description: "We offer a leading chauffeur service for luxurious driving experiences, guaranteeing an unparalleled high-level experience. At VIP Driver’s, comfort, safety, and style are at the forefront to provide our discerning customers with the best possible experience. Our team of experienced and dedicated professional chauffeurs always strives for the highest service quality and customer satisfaction. Our passion for exceptional customer service drives us to exceed your expectations.",
                service1_1: "Chauffeur service",
                service1_2: " for comfortable and stylish rides",

                service2_1: "Airport transfers",
                service2_2: " with premium comfort and punctual pickup",

                service3_1: "Long-distance transfers",
                service3_2: " for relaxed and safe travels",

                service4_1: "Errand and courier services",
                service4_2: " with reliable and fast service",

            },
            section2: {
                title: "Our Customized Solutions for Your Needs",
                description: "Discover our tailored solutions for your needs! Our services include airport transfers, city rides, business trips, errand runs, and customized tours. We also offer bespoke options to ensure your specific requirements are met. Benefit from our professionalism and discretion. Our transparent flat rates guarantee that you have no hidden costs and always know exactly what you are paying.",
                service1_1: "Exclusive limousine service",
                service1_2: " for discerning clients",

                service2_1: "Flexible and customized tours",
                service2_2: ", tailored to your needs and interests",

                service3_1: "Hourly bookings",
                service3_2: " for maximum flexibility and convenience",

                service4_1: "Event rides",
                service4_2: " for smooth operations at events"

            },
            section3: {
                title: "First-Class Vehicles for Your Comfort",
                description: "Experience the ultimate chauffeur service with VIP Driver’s GmbH. Our extensive fleet offers luxurious limousines, comfortable SUVs, and elegant vans. All vehicles are meticulously maintained and cleaned to make your ride an unforgettable experience. Let us take care of your travel plans and provide you with an unmatched driving experience. We look forward to chauffeuring you!",
                service1_1: "Shuttles",
                service1_2: " for comfortable and reliable transportation to events, hotels, or other destinations",

                service2_1: "Sightseeing tours",
                service2_2: " for cultural discoveries and historical highlights",

                service3_1: "Excursion rides",
                service3_2: " to the most beautiful and interesting places",

                service4_1: "Romantic weddings",
                service4_2: " with elegant vehicles and professional service"

            }
        }

        

    },

    accordionData: {
        DE: {
            heading:{
                title: "FAQs über LimousinenService - Das wird auch gefragt!"
            },
            panels: {
                panel1: {
                    title: "Was passiert, wenn ich mehr Gepäck oder Gäste habe, als in der Buchung vorgesehen?",
                    description: "Die bei jeder Buchung vorgesehene Obergrenze für Gepäck dient Ihrer Sicherheit. Wir empfehlen deshalb, diese Obergrenze nicht zu überschreiten. Unsere Chauffeure behalten sich das Recht vor, den Service aufgrund zu vieler Gepäckstücke zu verweigern. Überschreitungen der Obergrenze für Gepäckstücke können zudem zu zusätzlichen Gebühren führen. Da uns die Sicherheit unserer Gäste besonders am Herzen liegt, können Chauffeure den Service verweigern, wenn die Anzahl an Gästen oder Gepäckstücken ein Sicherheitsrisiko darstellt."
                },
                panel2: {
                    title: "Kann ich mein Handy im Fahrzeug aufladen?",
                    description: "Selbstverständlich! Unsere Fahrzeuge sind mit USB-Anschlüssen ausgestattet, damit Sie Ihre Geräte während der Fahrt aufladen können. Bitte teilen Sie uns bei der Buchung mit, wenn Sie spezielle Anforderungen haben, damit wir sicherstellen können, dass Ihr Anliegen berücksichtigt wird."
                },
                panel3: {
                    title: "Öffnet der Chauffeur die Türen für Fahrgäste?",
                    description: "Ja, selbstverständlich! Alle unsere Chauffeure öffnen höflicherweise die Türen für unsere Kunden und stehen bei Bedarf zur Hilfe bereit. Unser Ziel ist es, einen qualitativ hochwertigen und umfassenden Service anzubieten, der auf die Bedürfnisse und Wünsche unserer Kunden zugeschnitten ist."
                },
                panel4: {
                    title: "Darf ich im Fahrzeug rauchen?",
                    description: "Um für alle Gäste die Sauberkeit und eine gute Luftqualität im Inneren des Fahrzeugs zu garantieren, ist das Rauchen in Fahrzeugen nicht gestattet. Dies beinhaltet Zigaretten, E-Zigaretten, Zigarren und sämtliche andere tabak- und nicht tabakhaltige Produkte, die über das Rauchen konsumiert werden. Ein Verstoß gegen diese Regel kann zu zusätzlichen Gebühren führen."
                },
                panel5: {
                    title: "Welche Sprache spricht mein Chauffeur?",
                    description: "Alle Chauffeure sprechen sowohl Englisch als auch Deutsch."
                },
                panel6: {
                    title: "Was passiert, wenn mein Zug oder Flug Verspätung hat?",
                    description: "Bitte geben Sie bei der Buchung Ihre Flug-/Zugnummer für alle Abholungen vom Flughafen und Bahnhof an, damit wir deren Status verfolgen und die Abholzeit bei verspäteter oder verfrühter Ankunft anpassen können."
                },
                panel7: {
                    title: "Warum sollte ich meine Flugnummer angeben?",
                    description: "Die Flugnummer ermöglicht es uns, den Status des Fluges zu überprüfen und die Abholzeit bei einer verspäteten oder verfrühten Ankunft anzupassen. Außerdem kann so sichergestellt werden, dass Ihr Chauffeur am richtigen Terminal wartet."
                },
                panel8: {
                    title: "Wo wird der Chauffeur bei einer Abholung von einem Flughafen auf mich warten?",
                    description: "Alle Fahrten beinhalten einen kostenlosen Meet & Greet-Service für Abholungen von Flughäfen. Der Chauffeur wartet am Ausgang nach der Gepäckausgabe mit einem personalisierten Abholschild. Bei Abholungen vom Flughafen, bei denen der Treffpunkt ein anderer ist, wird der genaue Ort in der E-Mail mit der Buchungsbestätigung angegeben."
                },
                panel9: {
                    title: "Wo wird der Chauffeur bei einer Abholung von einem Bahnhof auf mich warten?",
                    description: "Bei Abholungen vom Bahnhof enthält die Bestätigungs-E-Mail den genauen Straßennamen für Ihre Abholung, so dass Sie wissen, welchen Ausgang Sie wählen sollten. Bei Bedarf kann der Gast den Chauffeur jederzeit anrufen, um einen anderen Abholort zu vereinbaren."
                }, 
                panel10: {
                    title: "Bieten sie einen Event- oder VIP-Service an?",
                    description: "Ja, dass engagierte Event-Team von kann weltweit die gesamte Organisation und Durchführung des Bodentransports für Ihre Veranstaltungen oder VIP-Services übernehmen. So können Sie Ihre Veranstaltung genießen während wir die Planung, Durchführung und Koordination vor Ort übernehmen."
                }, 
                panel11: {
                    title: "Was kann ich in das Feld 'Besondere Wünsche / Hinweis' an den Chauffeur eintragen?",
                    description: "Dieses Feld dient der Angabe von notwendigen Informationen für Ihre Fahrt oder der Anfrage außerordentlicher Leistungen."
                }, 
                panel12: {
                    title: "Wie lange wird der Chauffeur auf mich warten?",
                    description: "Bei einer normalen Abholung (z.B. von einem Hotel oder Ihrem Zuhause) wartet Ihr Chauffeur 30 Minuten auf Sie, bevor er den Abholort verlässt. Abholungen von Flughäfen und Bahnhöfen beinhalten eine kostenlose Wartezeit von 60 Minuten, bevor Ihr Chauffeur den Abholort verlässt. Durch die Angabe Ihrer Flug- bzw. Zugnummer ermöglichen Sie Ihrem Chauffeur, die Abholzeit einer möglichen Verspätung oder verfrühten Ankunft anzupassen."
                },
                panel13: {
                    title: "Wie erhalte ich meine Buchungsbestätigung?",
                    description: "Sie erhalten sämtliche Bestätigungen via Email."
                },
                panel14: {
                    title: "Darf ich Getränke und Nahrungsmittel mit ins Fahrzeug nehmen?",
                    description: "Saubere Fahrzeuge sind ein wesentlicher Bestandteil unseres Service, weshalb wir Sie bitten, während der Fahrt nicht zu essen. Sollte das Fahrzeug aufgrund verschütteter Speisen oder Getränke eine Reinigung benötigen, können zusätzliche Kosten anfallen. In allen Fahrzeugen befindet sich Wasser, das für Sie selbstverständlich kostenlos ist."
                },
                panel15: {
                    title: "Wie bekomme ich verlorenes Eigentum zurück?",
                    description: "Wenn Sie oder der Gast, für den Sie buchen, nach der Fahrt einen Gegenstand im Fahrzeug zurücklassen, wendet sich der Chauffeur für die Rückgabe direkt an den Gast."
                },
                panel16: {
                    title: "Welche Zahlungsmöglichkeiten sind verfügbar?",
                    description: "Akzeptiert werden Überweisungen und Karten von EC, Visa, Paypal, Maestro, Mastercard und American Express."
                },
                panel17: {
                    title: "Wann erhalte ich meine Rechnung?",
                    description: "Ihre Rechnung wird Ihnen ca. 24 Stunden nach der Fahrt per Email zugesandt."
                }, 
            }
            

        },
        EN: {
            heading:{
                title: "FAQs about Limousine Service - These are frequently asked questions!"
            },
            panels: {
                panel1: {
                    title: "What happens if I have more luggage or guests than specified in the booking?",
                    description: "The maximum luggage limit specified for each booking is for your safety. Therefore, we recommend not exceeding this limit. Our chauffeurs reserve the right to refuse service due to excessive luggage. Exceeding the luggage limit may also result in additional charges. As the safety of our guests is our top priority, chauffeurs may refuse service if the number of guests or luggage poses a safety risk."
                },
                panel2: {
                    title: "Can I charge my phone in the vehicle?",
                    description: "Certainly! Our vehicles are equipped with USB ports so you can charge your devices during the journey. Please inform us when booking if you have any specific requirements so we can ensure your needs are met."
                },
                panel3: {
                    title: "Does the chauffeur open the doors for passengers?",
                    description: "Yes, absolutely! All our chauffeurs courteously open doors for our customers and are ready to assist if needed. Our aim is to provide a high-quality and comprehensive service tailored to the needs and desires of our customers."
                },
                panel4: {
                    title: "Am I allowed to smoke in the vehicle?",
                    description: "To ensure cleanliness and good air quality inside the vehicle for all guests, smoking is not permitted in vehicles. This includes cigarettes, e-cigarettes, cigars, and all other tobacco and non-tobacco products consumed through smoking. Violation of this rule may result in additional charges."
                },
                panel5: {
                    title: "What language does my chauffeur speak?",
                    description: "All chauffeurs speak both English and German."
                },
                panel6: {
                    title: "What happens if my train or flight is delayed?",
                    description: "Please provide your flight/train number when booking for all airport and train station pickups so we can track their status and adjust the pickup time for delayed or early arrivals."
                },
                panel7: {
                    title: "Why should I provide my flight number?",
                    description: "The flight number allows us to check the status of the flight and adjust the pickup time for delayed or early arrivals. Additionally, this ensures that your chauffeur waits at the correct terminal."
                },
                panel8: {
                    title: "Where will the chauffeur wait for me for an airport pickup?",
                    description: "All rides include a complimentary Meet & Greet service for airport pickups. The chauffeur waits at the exit after baggage claim with a personalized pickup sign. For airport pickups where the meeting point is different, the exact location will be provided in the booking confirmation email."
                },
                panel9: {
                    title: "Where will the chauffeur wait for me for a train station pickup?",
                    description: "For train station pickups, the confirmation email contains the exact street name for your pickup, so you know which exit to choose. If necessary, the guest can call the chauffeur at any time to arrange a different pickup location."
                },
                panel10: {
                    title: "Do you offer event or VIP services?",
                    description: "Yes, our dedicated event team can handle the entire organization and execution of ground transportation for your events or VIP services worldwide. This allows you to enjoy your event while we take care of the planning, execution, and on-site coordination."
                },
                panel11: {
                    title: "What can I enter in the 'Special Requests / Notes' field for the chauffeur?",
                    description: "This field is for providing necessary information for your ride or requesting extraordinary services."
                },
                panel12: {
                    title: "How long will the chauffeur wait for me?",
                    description: "For a regular pickup (e.g., from a hotel or your home), your chauffeur will wait for you for 30 minutes before leaving the pickup location. Airport and train station pickups include a complimentary waiting time of 60 minutes before your chauffeur leaves the pickup location. By providing your flight or train number, you enable your chauffeur to adjust the pickup time for possible delays or early arrivals."
                },
                panel13: {
                    title: "How do I receive my booking confirmation?",
                    description: "You will receive all confirmations via email."
                },
                panel14: {
                    title: "Can I bring drinks and food into the vehicle?",
                    description: "Clean vehicles are an essential part of our service, so we ask that you do not eat during the journey. If the vehicle requires cleaning due to spilled food or drinks, additional charges may apply. Water is provided in all vehicles, which is of course free for you."
                },
                panel15: {
                    title: "How do I get back lost property?",
                    description: "If you or the guest you're booking for leave an item in the vehicle after the ride, the chauffeur will contact the guest directly for its return."
                },
                panel16: {
                    title: "What payment methods are available?",
                    description: "We accept transfers and cards from EC, Visa, Paypal, Maestro, Mastercard, and American Express."
                },
                panel17: {
                    title: "When will I receive my invoice?",
                    description: "Your invoice will be sent to you via email approximately 24 hours after the ride."
                }
            }
            
        }
    },
    accordionButton: {
        DE: {
            more: "Mehr anzeigen",
            less: "Weniger anzeigen"
        }, 
        EN: {
            more: "Show more",
            less: "Show less"
        }
    },

    fleetsection: {
        DE: {
            title: "Unsere Flotte",
            description: "Unser Fuhrpark ist auf Komfort und Sicherheit ausgelegt. Wir bieten Ober- und Luxusklasse-Neuwagen mit vielen Annehmlichkeiten. Unsere Ledersitze bieten viel Bewegungsfreiheit für entspannte Fahrten. Dank stoßhemmender Fahrwerkstechnik spüren Sie die Straße kaum. Unsere Limousinen sind multimedial vernetzt, für Unterhaltung oder effiziente Arbeit.",
            class1: "Business Class",
            class2: "Elektro",
            class3: "First Class",
            class4: "VAN",
            class5: "SUV",
        },
        EN: {
            title: "Our Fleet",
            description: "Our fleet is designed for comfort and safety. We offer upper and luxury class new cars with many amenities. Our leather seats provide ample freedom of movement for relaxed journeys. Thanks to shock-absorbing chassis technology, you'll hardly feel the road. Our limousines are multimedia-connected, for entertainment or efficient work.",
            class1: "Business Class",
            class2: "Electric",
            class3: "First Class",
            class4: "VAN",
            class5: "SUV",
        }
    },

    //Karriere
    carreer1: {
        DE: {
            title: "Sind Sie bereit für eine aufregende Reise?",
            description: "Sind Sie daran interessiert, in einem globalen, fesselnden und einladenden Umfeld als Fahrer zu arbeiten?",
            button: "Jetzt Bewerben!"
        },
        EN: {
            title: "Are you ready for an exciting journey?",
            description: "Are you interested in working as a driver in a global, captivating, and welcoming environment?",
            button: "Apply Now!"
        }
    },

    carreer2: {
        DE: {
            back: "Zurück zur Karriere",
            title: "Stellenangebot: Chauffeur gesucht!",
            description1: "Hier sind die Aufgaben, die auf Sie warten:",
            description2: "Durchführung von Fahrten mit hochwertigen Limousinen",
            description3: "Planung von Fahrtrouten und Zeitplänen",
            description4: "Veranstaltungsfahrten",
            description5: "Langstreckenfahrten",
            description6: "Folgende Anforderungen haben wir:",
            description7: "Mindestalter von 21 Jahren",
            description8: "Führerscheinklasse B erforderlich",
            description9: "Gepflegtes Erscheinungsbild und professionelle Umgangsformen",
            description10: "Personenbeförderungsschein (P-Schein) erforderlich",
            description11: "Was wir bieten:",
            description12: "Überdurchschnittliches Gehalt",
            description13: "Flexible Arbeitszeiten! Sie können selbst entscheiden, welche Fahrten in Ihren Zeitplan passen.",
            description14: "Arbeiten in einem freundlichen Umfeld mit tollen Kollegen und netten Kunden",
            description15: "Was wir erwarten:",
            description16: "Organisationstalent",
            description17: "Freude am Fahren",
            textfield1: "Name",
            textfield2: "Telefon",
            textfield3: "E-Mail",
            textfield4: "Nachricht",
            uploaddesc: "Fügen Sie bitte Ihren Lebenslauf hinzu, falls vorhanden.",
            button: "Abschicken"
        },
        EN: {
            back: "Back to Career",
            title: "Job Opening: Chauffeur Wanted!",
            description1: "Here are the tasks awaiting you:",
            description2: "Conducting rides with high-end limousines",
            description3: "Planning driving routes and schedules",
            description4: "Event transportation",
            description5: "Long-distance drives",
            description6: "We have the following requirements:",
            description7: "Minimum age of 21",
            description8: "Driver's license class B required",
            description9: "Neat appearance and professional demeanor",
            description10: "Passenger transportation license (P-License) required",
            description11: "What we offer:",
            description12: "Above-average salary",
            description13: "Flexible working hours! You can decide which rides fit into your schedule.",
            description14: "Working in a friendly environment with great colleagues and nice customers",
            description15: "What we expect:",
            description16: "Organizational skills",
            description17: "Enjoyment of driving",
            textfield1: "Name",
            textfield2: "Phone",
            textfield3: "Email",
            textfield4: "Message",
            uploaddesc: "Please attach your resume if available.",
            button: "Submit"
        }
    },

    services: {
        DE: {
            title: "Unsere Leistungen",
            service1: "Chauffeurservice",
            service2: "Flughafentransfers",
            service3: "Bahnhofabholungen",
            service4: "Langstreckentransfers",
            service5: "⁠Besorgungs- und Kurierfahrten",
            service6: "Limousinenservice",
            service7: "Einkaufstouren",
            service8: "Besichtigungstouren",
            service9: "⁠Flexible und individuelle Touren",
            service10: "Ausflugsfahrten",
            service11: "⁠Stündliche Buchungen",
            service12: "Veranstaltungsfahrten",
            service13: "Hochzeiten",
            service14: "Shuttle",

            cards: {
                card1: {
                    title: "Individuelle Touren",
                    description: "Wir bieten Ihnen maßgeschneiderte Touren, damit Sie Ihre Reise nach Ihren individuellen Wünschen gestalten können.",
                },
                card2: {
                    title: "Transfers",
                    description: "Bei VIP Driver's GmbH bieten wir zuverlässige und komfortable Transfers - damit Sie entspannt und pünktlich an Ihr Ziel kommen.",
                },
                card3: {
                    title: "Gepflegte Fahrzeuge",
                    description: "Unsere Fahrzeuge sind immer in makellosem Zustand und bieten Ihnen höchsten Komfort und Sicherheit",
                },
                card4: {
                    title: "Festpreise",
                    description: "Wir bieten transparente Festpreise für unseren erstklassigen Chauffeurservice.",
                },
                card5: {
                    title: "Kostenlose Stornierungen",
                    description: "Sie können Ihre Buchung 12h vor Fahrtbeginn kostenlos stornieren - wir möchten sicherstellen, dass Sie vollständig zufrieden sind.",
                },
                card6: {
                    title: "Mehrsprachig",
                    description: "Unsere Chauffeure sprechen Englisch und Deutsch sowie weitere Sprachen.",
                }
            }
        },
        
        EN: {
            title: "Our Services",
            service1: "Chauffeur Service",
            service2: "Airport Transfers",
            service3: "Train Station Pickups",
            service4: "Long-Distance Transfers",
            service5: "Errand and Courier Services",
            service6: "Limousine Service",
            service7: "Shopping Tours",
            service8: "Sightseeing Tours",
            service9: "Flexible and Customized Tours",
            service10: "Excursion Rides",
            service11: "Hourly Bookings",
            service12: "Event Rides",
            service13: "Weddings",
            service14: "Shuttle",

            cards: {
                card1: {
                    title: "Customized Tours",
                    description: "We offer tailored tours so you can design your journey according to your individual preferences.",
                },
                card2: {
                    title: "Transfers",
                    description: "At VIP Driver's GmbH, we provide reliable and comfortable transfers to ensure you arrive at your destination relaxed and on time.",
                },
                card3: {
                    title: "Well-Maintained Vehicles",
                    description: "Our vehicles are always in immaculate condition, offering you the highest level of comfort and safety.",
                },
                card4: {
                    title: "Fixed Prices",
                    description: "We offer transparent fixed prices for our premium chauffeur service.",
                },
                card5: {
                    title: "Free Cancellations",
                    description: "You can cancel your booking free of charge up to 12 hours before the start of the journey - we want to ensure your complete satisfaction.",
                },
                card6: {
                    title: "Multilingual",
                    description: "Our chauffeurs speak English and German, as well as other languages.",
                }
            }
        }
    },

    buchung: {
        DE: {
            map: {
                desc1: "Distanz",
                desc2: "Dauer",
                desc3: "Std."
            },
            formular: {
                tab1: "TRANSFER",
                tab2: "STÜNDLICH",
                input1: "Ihr Abholort *",
                input2: "Ihr Zielort *",
                input3: "Dauer *",
                input4: "Datum *",
                input5: "Zeit *",
                input6: "Vorname",
                input7: "Nachname",
                input8: "E-Mail",
                input9: "Telefon",
                input10: "Firmenname",
                input11: "Flugnummer / Zugnummer",
                input12: "Rabattcode",
                input13: "Nachricht",
                desc1: "Bitte fügen Sie Sonderwünsche hinzu, z. B. die Anzahl der Taschen, Kindersitze usw. . Bitte geben Sie keine vertraulichen Informationen an.",
                discount: "Falls Sie ein Neukunde sind, verwenden Sie den Rabattcode: 'neukunde10'; wenn Sie ein Unternehmen sind, nutzen Sie bitte den Rabattcode: 'firma20'."
            },
            transportmethod: {
                title: "Wählen Sie Ihre Transportklasse:",
                description1: "Alle Klassen beinhalten:",
                description2: "Kostenlose Stornierung bis zu 12 Stunden vor Abholung",
                description3: "Gratis 30 Minuten Wartezeit",
                description4: "Treffen und Begrüßung",
                description5: "Eine Flasche Wasser gratis",
                description6: "Bitte beachten Sie:",
                description7: "Der Mindestpreis beträgt 60€.",
                description8: "Die oben abgebildeten Fahrzeuge sind Beispiele. Es kann sein, dass Sie ein anderes Fahrzeug von ähnlicher Qualität erhalten",
                description9: "Gäste-/Gepäckkapazitäten müssen aus Sicherheitsgründen eingehalten werden. Wenn Sie sich nicht sicher sind, wählen Sie eine größere Klasse, da Chauffeure den Service ablehnen können, wenn sie überschritten werden."
            },
            paymentmethod: {
                label1: "Wählen Sie Ihre Zahlungsmethode:",
                img1: "/assets/payment/paypal.png",
                img2: "/assets/payment/kreditkarte.png",
                img3: "/assets/payment/ueberweisung.png",
            },
            button: "Anfrage abschicken",
        },
        EN: {
            map: {
                desc1: "Distance",
                desc2: "Duration",
                desc3: "Hrs."
            },
            formular: {
                tab1: "TRANSFER",
                tab2: "HOURLY",
                input1: "Your Pickup Location *",
                input2: "Your Destination *",
                input3: "Duration *",
                input4: "Date *",
                input5: "Time *",
                input6: "First Name",
                input7: "Last Name",
                input8: "Email",
                input9: "Phone",
                input10: "Company Name",
                input11: "Flight Number / Train Number",
                input12: "Discount Code",
                input13: "Message",
                desc1: "Please include any special requests, such as the number of bags, child seats, etc. Please do not provide any confidential information.",
                discount: "If you're a new customer, use the discount code 'neukunde10'; if you're a business, please use the discount code 'firma20'."
            },
            transportmethod: {
                title: "Choose Your Transport Class:",
                description1: "All classes include:",
                description2: "Free cancellation up to 12 hours before pickup",
                description3: "Free 30 minutes waiting time",
                description4: "Meet and greet",
                description5: "Complimentary bottle of water",
                description6: "Please note:",
                description7: "Minimum fare is 60€.",
                description8: "The vehicles pictured above are examples. You may receive a different vehicle of similar quality.",
                description9: "Guest/luggage capacities must be adhered to for safety reasons. If unsure, select a larger class as chauffeurs may refuse service if exceeded."
            },
            paymentmethod: {
                label1: "Choose Your Payment Method:",
                img1: "/assets/payment/paypal.png",
                img2: "/assets/payment/creditcard.png",
                img3: "/assets/payment/banktransfer.png",
            },
            button: "Submit Inquiry",
        }
    },

    contact: {
        DE: {
            title: "Kontaktieren Sie uns!",
            description1: "VIP Driver's GmbH",
            description2: "Edisonstraße 6",
            description3: "85716 Unterschleißheim, Deutschland",
            description4: "Tel.: +49 89 289 42 438",
            description5: "Notfälle: +49 1573 0065258",
            description6: "E-Mail.: kontakt@vipdrivers.de",
            description7: "Öffnungszeiten:",
            description8: "Mo. - Fr.",
            description9: "08:00 - 20:00 Uhr",
            description10: "Sa.",
            description11: "10:00 - 16:00 Uhr",
            description12: "So.",
            description13: "Geschlossen",

            textfield1: "Name",
            textfield2: "Telefon",
            textfield3: "E-Mail",
            textfield4: "Nachricht",
            button: "Abschicken",
        },
        EN: {
            title: "Contact Us!",
            description1: "VIP Driver's GmbH",
            description2: "Edisonstraße 6",
            description3: "85716 Unterschleißheim, Germany",
            description4: "Tel.: +49 89 289 42 438",
            description5: "Emergencies: +49 1573 0065258",
            description6: "Email: kontakt@vipdrivers.de",
            description7: "Opening Hours:",
            description8: "Mon. - Fri.",
            description9: "08:00 AM - 08:00 PM",
            description10: "Sat.",
            description11: "10:00 AM - 04:00 PM",
            description12: "Sun.",
            description13: "Closed",
            textfield1: "Name",
            textfield2: "Phone",
            textfield3: "Email",
            textfield4: "Message",
            uploaddesc: "Please attach your resume if available.",
            button: "Submit",
        }
    },

    footer: {
        DE: {
            column1: {
                title: "Kontakt",
                link1: "VIP Driver's GmbH",
                link2: "Tel.: +49 89 289 42 438",
                link3: "E-Mail: kontakt@vipdrivers.de",
            }, 
            column2: {
                title: "Rechtliches",
                link1: "Impressum",
                link2: "Datenschutz"
            },
            column3: {
                title: "Quick Links",
                link1: "Home",
                link2: "Leistungen",
                link3: "Karriere",
                link4: "Buchung"
            },
            column4: {
                title: "Unser Service",
                link1: "Limousinen- und Chauffeurservice",
                link2: "Flughafentransfers",
                link3: "Veranstaltungsfahrten",
                link4: "⁠Stündliche Buchungen"
            }, 
            lastrow: {
                left: "© Alle Rechte vorbehalten der VIP Driver's GmbH",
                right: "Designed by Monsec Webdesign"
            }
        },
        EN: {
            column1: {
                title: "Contact",
                link1: "VIP Driver's GmbH",
                link2: "Tel.: +49 89 289 42 438",
                link3: "Email: kontakt@vipdrivers.de"
            }, 
            column2: {
                title: "Legal",
                link1: "Imprint",
                link2: "Data Privacy"
            },
            column3: {
                title: "Quick Links",
                link1: "Home",
                link2: "Services",
                link3: "Careers",
                link4: "Booking"
            },
            column4: {
                title: "Our Services",
                link1: "Limousine and Chauffeur Service",
                link2: "Airport Transfer",
                link3: "Event Shuttle Transfers",
                link4: "Hourly bookings"
            },
            lastrow: {
                left: "© All rights reserved by VIP Driver's GmbH",
                right: "Designed by Monsec Webdesign"
            }
        },
    }
    
};