import React,{useState, useEffect, useContext} from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import InputItem from './InputItem';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { SourceContext } from '../../context/SourceContext';
import { DestinationContext } from '../../context/DestinationContext';
import { DateTimeContext } from '../../context/DateTimeContext';
import Select from '@mui/material/Select';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import MenuItem from '@mui/material/MenuItem';
import { DurationContext } from '../../context/DurationContext';
import { TransportArtContext } from '../../context/TransportArt';
import { TextData } from '../../source/textData'
import { LanguageContext } from "../../context/LanguageContext";



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



 function SearchSection () {
    
    const {transportArt, setTransportArt} = useContext(TransportArtContext);
    const {datetime, setDateTime} = useContext(DateTimeContext);
    const {duration, setDuration} = useContext(DurationContext);
    const { language } = useContext(LanguageContext);


    const handleDuration = (event) => {
      setDuration(event.target.value);
    };

    const handleChange = (event, newValue) => {
      setTransportArt(newValue);
    };

    const currentDate = dayjs().add(3, 'hour');
    const minSelectableTime = datetime.isSame(currentDate, 'day') ? currentDate : datetime.startOf('day');
         
      return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="form">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width:'100%' }}>
              <Tabs value={transportArt} TabIndicatorProps={{ style: { display: 'none' }}} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={TextData.searchSection[language].tab1} {...a11yProps(0)} style={{minWidth: '50%', fontSize:' 16px'}} sx={{
                  borderBottom: transportArt === 0 ? '2px solid #1f2c34' : '2px solid transparent',
                  '&:selected': { backgroundColor: 'transparent' },
                  '&.Mui-selected': { color: 'black'}}}/>
                <Tab label={TextData.searchSection[language].tab2} {...a11yProps(1)} style={{minWidth: '50%', fontSize:' 16px'}} sx={{
                  borderBottom: transportArt === 1 ? '2px solid #1f2c34' : '2px solid transparent',
                  '&:selected': { backgroundColor: 'transparent' },
                    '&.Mui-selected': { color: 'black'}}}/>
              </Tabs>
            </Box>
            <CustomTabPanel value={transportArt} index={0}>
              <Grid style={{boxSizing:'border-box'}} container>
                <Grid item xs={12} md={12} sx={{pt:0}}>
                  <InputItem type="source"/>
                </Grid>
                <Grid item xs={12} md={12} sx={{pt:2}}>
                  <InputItem type="destination"/>
                </Grid>
                <Grid item xs={12} md={12} sx={{pt:2}}>
                  <Box component="section" display="flex" style={{position: 'relative'}}  alignItems="center" sx={{boxSizing:'border-box',bgcolor:"#fafafa", pl: 1, border: 'none', width:'100%' }}>
                      <label className="mobiledatepicker-label">{TextData.searchSection[language].input4}</label>
                        <CalendarMonthOutlinedIcon/>
                        <div className="mobiledatepicker-wrapper">
                          <MobileDatePicker  orientation="portrait" className="gm-datepicker"
                              format="DD.MM.YYYY"
                              value={datetime}
                              onChange={(newValue) => setDateTime(newValue)}
                              minDate={dayjs().add(3, 'hour')}
                              slotProps={{
                                // Targets the `InputAdornment` component.
                                openPickerButton: {
                                  color: 'primary',
                                },
                                inputAdornment: {
                                  position: 'start',
                                },
                              }}
                              sx={{'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' },}}
                            />
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} sx={{pt:2}}>
                  <Box component="section" display="flex" style={{position: 'relative'}}  alignItems="center" sx={{boxSizing:'border-box',bgcolor:"#fafafa", pl: 1, border: 'none', width:'100%' }}>
                      <label className="mobiledatepicker-label">{TextData.searchSection[language].input5}</label>
                        <AccessTimeOutlinedIcon/>
                        <div className="mobiledatepicker-wrapper">
                          <MobileTimePicker  orientation="portrait" className="gm-datepicker"
                              value={datetime}
                              ampm={false}
                              onChange={(newValue) => setDateTime(newValue)}
                              minTime={minSelectableTime}
                              slotProps={{
                                // Targets the `InputAdornment` component.
                                openPickerButton: {
                                  color: 'primary',
                                },
                                inputAdornment: {
                                  position: 'start',
                                },
                              }}
                              sx={{'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' }}}
                            />
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} sx={{pt:2}}>
                <Link to="/buchung">
                  <Button   variant="contained" fullWidth sx={{
                    '&:hover': {backgroundColor: '#DBB47E', boxShadow: 'none',}, 
                    '&:active': {backgroundColor: '#DBB47E', boxShadow: 'none'}, 
                    color: 'black', bgcolor: '#DBB47E', padding: '16px 32px', boxShadow: 'none' }}>{TextData.searchSection[language].button}</Button>
                </Link>
                </Grid>
              </Grid>
            </CustomTabPanel>
            
            <CustomTabPanel value={transportArt} index={1}>
            <Grid style={{boxSizing:'border-box'}} container>
                <Grid item xs={12} md={12} sx={{pt:0}}>
                  <InputItem type="source"/>
                </Grid>
                <Grid item xs={12} md={12} sx={{pt:2}}>
                <Box component="section" display="flex" style={{position: 'relative'}}  alignItems="center" sx={{pt: 0,boxSizing:'border-box',bgcolor:"#fafafa", pl: 1, border: 'none', width:'100%' }}>
                      <label className="mobiledatepicker-label">{TextData.searchSection[language].input3}</label>
                        <TimerOutlinedIcon/>
                        <div className="mobiledatepicker-wrapper">
                          <Select
                            displayEmpty
                            required
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 48 * 4.5 + 8,
                                  width: 250,
                                },
                              },
                            }}
                            value={duration}
                            onChange={handleDuration}
                            label="Age"
                            fullWidth
                            sx={{'& .MuiSelect-iconOutlined': {top: 'calc(50% - .8em)'},'& .MuiSelect-select':{padding: 0, paddingTop: '2px'},'& .MuiOutlinedInput-notchedOutline': {padding:0, border: 'none' },}}
                            >
                            <MenuItem value={40}>{TextData.searchSection[language].dauer1}</MenuItem>
                            <MenuItem value={60}>{TextData.searchSection[language].dauer2}</MenuItem>
                            <MenuItem value={80}>{TextData.searchSection[language].dauer3}</MenuItem>
                            <MenuItem value={100}>{TextData.searchSection[language].dauer4}</MenuItem>
                            <MenuItem value={120}>{TextData.searchSection[language].dauer5}</MenuItem>
                            <MenuItem value={140}>{TextData.searchSection[language].dauer6}</MenuItem>
                            <MenuItem value={160}>{TextData.searchSection[language].dauer7}</MenuItem>
                            <MenuItem value={180}>{TextData.searchSection[language].dauer8}</MenuItem>
                            <MenuItem value={200}>{TextData.searchSection[language].dauer9}</MenuItem>
                            <MenuItem value={220}>{TextData.searchSection[language].dauer10}</MenuItem>
                            <MenuItem value={240}>{TextData.searchSection[language].dauer11}</MenuItem>
                            <MenuItem value={260}>{TextData.searchSection[language].dauer12}</MenuItem>
                            <MenuItem value={280}>{TextData.searchSection[language].dauer13}</MenuItem>
                            <MenuItem value={300}>{TextData.searchSection[language].dauer14}</MenuItem>
                            <MenuItem value={320}>{TextData.searchSection[language].dauer15}</MenuItem>
                            <MenuItem value={340}>{TextData.searchSection[language].dauer16}</MenuItem>
                            <MenuItem value={360}>{TextData.searchSection[language].dauer17}</MenuItem>
                            <MenuItem value={380}>{TextData.searchSection[language].dauer18}</MenuItem>
                            <MenuItem value={400}>{TextData.searchSection[language].dauer19}</MenuItem>
                            <MenuItem value={420}>{TextData.searchSection[language].dauer20}</MenuItem>
                            <MenuItem value={440}>{TextData.searchSection[language].dauer21}</MenuItem>
                            <MenuItem value={460}>{TextData.searchSection[language].dauer22}</MenuItem>
                            <MenuItem value={480}>{TextData.searchSection[language].dauer23}</MenuItem>
                            <MenuItem value={960}>{TextData.searchSection[language].dauer24}</MenuItem>
                            <MenuItem value={1440}>{TextData.searchSection[language].dauer25}</MenuItem>
                            <MenuItem value={1920}>{TextData.searchSection[language].dauer26}</MenuItem>
                            <MenuItem value={2400}>{TextData.searchSection[language].dauer27}</MenuItem>
                          </Select>
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} sx={{pt:2}}>
                  <Box component="section" display="flex" style={{position: 'relative'}}  alignItems="center" sx={{boxSizing:'border-box',bgcolor:"#fafafa", pl: 1, border: 'none', width:'100%' }}>
                      <label className="mobiledatepicker-label">{TextData.searchSection[language].input4}</label>
                        <CalendarMonthOutlinedIcon/>
                        <div className="mobiledatepicker-wrapper">
                          <MobileDatePicker  orientation="portrait" className="gm-datepicker"
                              format="DD.MM.YYYY"
                              value={datetime}
                              minDate={dayjs().add(3, 'hour')}
                              onChange={(newValue) => setDateTime(newValue)}
                              slotProps={{
                                // Targets the `InputAdornment` component.
                                openPickerButton: {
                                  color: 'primary',
                                },
                                inputAdornment: {
                                  position: 'start',
                                },
                              }}
                              sx={{'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' },}}
                            />
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} sx={{pt:2}}>
                  <Box component="section" display="flex" style={{position: 'relative'}}  alignItems="center" sx={{boxSizing:'border-box',bgcolor:"#fafafa", pl: 1, border: 'none', width:'100%' }}>
                      <label className="mobiledatepicker-label">{TextData.searchSection[language].input5}</label>
                        <AccessTimeOutlinedIcon/>
                        <div className="mobiledatepicker-wrapper">
                          <MobileTimePicker  orientation="portrait" className="gm-datepicker"
                              value={datetime}
                              ampm={false}
                              minTime={minSelectableTime}
                              onChange={(newValue) => setDateTime(newValue)}
                              slotProps={{
                                // Targets the `InputAdornment` component.
                                openPickerButton: {
                                  color: 'primary',
                                },
                                inputAdornment: {
                                  position: 'start',
                                },
                              }}
                              sx={{'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' }}}
                            />
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} sx={{pt:2}}>
                <Link to="/buchung">
                  <Button   variant="contained" fullWidth sx={{
                    '&:hover': {backgroundColor: '#DBB47E', boxShadow: 'none',}, 
                    '&:active': {backgroundColor: '#DBB47E', boxShadow: 'none'}, 
                    color: 'black', bgcolor: '#DBB47E', padding: '16px 32px', boxShadow: 'none' }}>{TextData.searchSection[language].button}</Button>
                </Link>
                </Grid>
              </Grid>
            </CustomTabPanel>
          </Box>
        </div>
        </LocalizationProvider>
      );

};
 
  
export default SearchSection;