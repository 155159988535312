import React, { useRef, useState, useContext } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from '@mui/material/Container';
import { CarListData } from '../../source/carData'
import RadioGroup from '@mui/material/RadioGroup';
import LuggageIcon from '@mui/icons-material/Luggage';
import GroupIcon from '@mui/icons-material/Group';
import { LanguageContext } from "../../context/LanguageContext";
import { TextData } from '../../source/textData';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './fleetSection.scss';

// import required modules
import { Pagination } from 'swiper/modules';


function FleetSection2() {

    const { language } = useContext(LanguageContext);
  
    return (
    <div className="flotte-home">
          <div className="flotte-klassen">
          <Container maxWidth="lg">
            <div className="flotte-beschreibung">
            <h2>{TextData.fleetsection[language].title}</h2>
            <p>{TextData.fleetsection[language].description}</p>
            </div>
          </Container>
      <Swiper
        pagination={{
          clickable: true,
        }}
        spaceBetween= {30}
        centeredSlides= {true}
        modules={[Pagination]}
        className="mySwiper"
        breakpoints={{
            100: {
              slidesPerView: 1.25,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1424: {
                slidesPerView: 5,
                spaceBetween: 30,
            }
          }}
      >
        <SwiperSlide>
            <div className="flotte-item">
                <img alt="fleet_business_class" src="/assets/cars-home/bmw-i5.jpg"/>
                <h3>{TextData.fleetsection[language].class1}</h3>
                <div className="flotteitem-icon-wrapper">
                  <div>
                      <p className="carlistitem-icon carlistitem-icon1"><GroupIcon/>3</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon2"><LuggageIcon/>2</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon3"><LuggageIcon/>1</p>
                  </div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="flotte-item">
                <img alt="fleet_electric_class" src="/assets/cars-home/mercedes-eqs.jpg"/>
                <h3>{TextData.fleetsection[language].class2}</h3>
                <div className="flotteitem-icon-wrapper">
                  <div>
                      <p className="carlistitem-icon carlistitem-icon1"><GroupIcon/>3</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon2"><LuggageIcon/>2</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon3"><LuggageIcon/>1</p>
                  </div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="flotte-item">
                <img alt="fleet_first_class" src="/assets/cars-home/bmw7er.jpg"/>
                <h3>{TextData.fleetsection[language].class3}</h3>
                <div className="flotteitem-icon-wrapper">
                  <div>
                      <p className="carlistitem-icon carlistitem-icon1"><GroupIcon/>3</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon2"><LuggageIcon/>2</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon3"><LuggageIcon/>1</p>
                  </div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="flotte-item">
                <img alt="fleet_van_class" src="/assets/cars-home/mercedes-v-klasse.jpg"/>
                <h3>{TextData.fleetsection[language].class4}</h3>
                <div className="flotteitem-icon-wrapper">
                  <div>
                      <p className="carlistitem-icon carlistitem-icon1"><GroupIcon/>5</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon2"><LuggageIcon/>5</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon3"><LuggageIcon/>2</p>
                  </div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="flotte-item">
                <img alt="fleet_suv_class" src="/assets/cars-home/rangerover.jpg"/>
                <h3>{TextData.fleetsection[language].class5}</h3>
                <div className="flotteitem-icon-wrapper">
                  <div>
                      <p className="carlistitem-icon carlistitem-icon1"><GroupIcon/>3</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon2"><LuggageIcon/>2</p>
                  </div>
                  <div>
                      <p className="carlistitem-icon carlistitem-icon3"><LuggageIcon/>2</p>
                  </div>
                </div>
            </div>
        </SwiperSlide>
      </Swiper>
      </div>
    </div>
    )
  }
  
  export default FleetSection2