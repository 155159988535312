export const CarListData = [
    {
        id: 1,
        name: 'Business Class',

        DE: {
            name: 'Business Class',
            desc: 'BMW i5 oder gleichwertig',
        },
        EN: {
            name: 'Business Class',
            desc: 'BMW i5 or equivalent',
        },
        seat: 3,
        luggage: 2,
        luggagesmall: 1,
        amount_under_60km: 2.95,
        amount_above_60km: 2.60,
        amount_above_200km:2.40,
        amount20km: 70,
        image: '/assets/cars/bmw-i5_3.png'
    },
    {
        id: 2,
        name: 'Elektro',

        DE: {
            name: 'Elektro',
            desc: 'Mercedes EQS oder gleichwertig',
        },
        EN: {
            name: 'Electric',
            desc: 'Mercedes EQS or equivalent',
        },
        seat: 3,
        luggage: 2,
        luggagesmall: 1,
        amount_under_60km: 3.00,
        amount_above_60km: 2.65,
        amount_above_200km:2.45,
        amount20km: 75,
        image: '/assets/cars/mercedes-eqs.png'
    },
    {
        id: 3,
        name: 'First Class',

        DE: {
            name: 'First Class',
            desc: 'BMW 7er oder gleichwertig',
        },
        EN: {
            name: 'First Class',
            desc: 'BMW 7 Series or equivalent',
        },
        seat: 3,
        luggage: 2,
        luggagesmall: 1,
        amount_under_60km: 3.25,
        amount_above_60km: 2.80,
        amount_above_200km: 2.60,
        amount20km: 90,
        image: '/assets/cars/bmw-7er.png'
    },
    {
        id: 4,
        name: 'VAN',

        DE: {
            name: 'VAN',
            desc: 'Mercedes V-Klasse oder gleichwertig',
        },
        EN: {
            name: 'VAN',
            desc: 'Mercedes V-Class or equivalent',
        },
        seat: 5,
        luggage: 5,
        luggagesmall: 2,
        amount_under_60km: 3.25,
        amount_above_60km: 2.80,
        amount_above_200km:2.60,
        amount20km: 80,
        image: '/assets/cars/mercedes-v-klasse.png'
    },
    {
        id: 5,
        name: 'SUV',
        
        DE: {
            name: 'SUV',
            desc: 'Range Rover oder gleichwertig',
        },
        EN: {
            name: 'SUV',
            desc: 'Range Rover or equivalent',
        },
        seat: 3,
        luggage: 2,
        luggagesmall: 2,
        amount_under_60km: 4.50,
        amount_above_60km: 4,
        amount_above_200km: 3.90,
        amount20km: 120,
        image: '/assets/cars/range-rover2.png'
    },
] 